import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import i18n from '../../i18n'

export default function CommunitiesLayout() {
    const location = useLocation()
    const scrollRef = useRef<any>(null);
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    
     
          scrollRef.current?.scrollTo({ top: 0, behavior: "instant" });
    
  }, [location.pathname, scrollRef.current]);
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        setScrollY(scrollRef.current.scrollTop);
      }
    };

    const divElement = scrollRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className=''>
        {/* <div className="h-[600px] flex items-center justify-center w-full">
            <div className='text-center max-w-[800px] space-y-2'>
                <p className="title-level1 w-full">Qorddu pour les commaunities</p>
                <p className='text-level1'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet sunt labore ducimus impedit
                </p>
            </div>
        </div> */}
        <div>

            <div className={`px-page pt-10  sticky top-0 bg-white z-[10000] ${scrollY > 0 && 'teddra-shadow'}`}>
                <div className='flex bar-h items-center pb-10 px-page border-b border-main  relative'>
                    <div className="max-w-page w-full mx-auto justify-between flex">
                        {/* <Link to={`${i18n.language}`} className='text-inherit'>
                            <button className={location.pathname === `/${i18n.language}` ? 'active' : ''}>
                                <p className='text-level2'>Plateform</p>
                                
                            </button>
                        
                        </Link> */}
                        <Link to={`${i18n.language}`}  className='text-inherit  '>
                            <button className={location.pathname === `/${i18n.language}` ? 'active' : ''}>
                                <p className='text-level2'>Qorddu, c'est quoi ?</p>
                                
                            </button>
                        
                        </Link>
                        <Link to={`${i18n.language}/qorddu-for`}  className='text-inherit  '>
                            <button className={location.pathname.includes('/qorddu-for') ? 'active' : ''}>
                                <p className='text-level2'>Avec qui ?</p>
                                
                            </button>
                        
                        </Link>
                        <Link to={`${i18n.language}/solution`}  className='text-inherit  '>
                            <button className={location.pathname.includes('/solution') ? 'active' : ''}>
                                <p className='text-level2'>Pour faire quoi ?</p>

                            </button>
                        
                        </Link>
                        <Link to={`${i18n.language}/comment`}  className='text-inherit  '>
                            <button className={location.pathname.includes('/comment') ? 'active' : ''}>
                                <p className='text-level2'>Comment ça marche ?</p>

                            </button>
                        
                        </Link>
                        {/* <Link to={`${i18n.language}/avantages`}  className='text-inherit  bg-white'>
                            <button className={location.pathname.includes('/avantages') ? 'active' : ''}>
                                <p className='text-level2'>Activer</p>

                            </button>
                        
                        </Link> */}
                        <Link to={`${i18n.language}/demo`}  className='text-inherit  '>
                            <button className={location.pathname.includes('/demo') ? 'active' : ''}>
                                <p className='text-level2'>Demandez une demo</p>

                            </button>
                        
                        </Link>

                    </div>

                </div>
            </div>
            <div className="h-7"></div>
            <div className=' overflow-y-auto h-[calc(100vh-221px)] overflow-x-hidden ' ref={scrollRef}      style={{ scrollBehavior: "smooth" }} // Ensures smooth scrolling
            >
                <div className="">
                    <Outlet/>
                </div>
            </div>
        </div>
    </div>
  )
}

import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'

type Props ={
    text:string
}
export default function Phrase({text}:Props) {
  return (
    <div className='space-x-7 flex'>
    <ChevronRightIcon className='w-[35px] h-[35px] '/>
        <div className='flex-1'>
        
            <p className="text-level2 !mt-0">
                {text}
            </p>
        </div>
    </div>
  )
}

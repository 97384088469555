import Blocks from './Blocks'


type Props = {
    text: JSX.Element,
    blocks:{
        columns:number,
        content: {
            img?: {type:'icon' | 'image', src:string},
            text:string |JSX.Element
        }[]
    },
    topElement?:JSX.Element,
    bottomElement?:JSX.Element
}
export default function CentredBlock({text, blocks, topElement, bottomElement}:Props) {
  return (
    <div>
                    
        {topElement}
        <div className={`relative  flex flex-col justify-center pb-block bg-skin-fill-inverted text-skin-inverted ${!topElement ? 'pt-block' : '-mt-[1px]'} ${!bottomElement && 'pb-block'}`}>
            <div className='space-y-4 px-page  max-w-page mx-auto'>
                <div className='flex justify-center items-center'>
                    <div className="w-full space-y-16">
                        <div className='text-center text-white'>
                           {text}
                        </div>
                        <Blocks columns={blocks.columns} content={blocks.content.map(b => ({text: b.text, center:true, img: b.img ? {...b.img, dimensions:'w-[130px] h-[130px]'} : undefined}))} />
                        {/* <div className="mt-10 space-y-8">
                            
                            <div className='grid grid-cols-2 gap-4 w-max'>
                                <div className='space-y-3'>
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                            <ReactSvg className='w-full h-full' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fvolumes%2Fit_icon.svg?alt=media&token=5cede60e-325e-42a0-9785-99b499f84602'/>
                                        </div>
                                        <p className='text-level2'>Technologies de l’Information</p>
                                    </div>
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                            <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Findustry.svg?alt=media&token=aa4500dd-49b3-4326-9021-a6b7f85e12cf'/>
                                        </div>
                                        <p className='text-level2'>Industrie</p>
                                    </div>
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                            <ReactSvg className='w-full h-full' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Ffood.svg?alt=media&token=939f401f-496e-4a94-9c2b-beaa43a9488d'/>
                                        </div>
                                        <p className='text-level2'>Agro-alimentaire</p>
                                    </div>
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                            <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Finnovation_places.svg?alt=media&token=8c05c329-a8cd-49bf-8f93-9b9b0fcc7da1'/>
                                        </div>
                                        <p className='text-level2'>Innovation Places</p>
                                    </div>
                                
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                            <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Ffinance.svg?alt=media&token=d133e024-0ac0-4504-8e8d-5f90f53f02c9'/>
                                        </div>
                                        <p className='text-level2'>Finance</p>
                                    </div>
                                
                            

                                </div>
                                <div className='space-y-3'>
                                <div className="flex space-x-3 items-center">
                                        <div className='icon-lg  '>
                                            <ReactSvg className='w-full h-full  ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Fentrepre.svg?alt=media&token=de159738-4cd7-4358-974c-22868af8c55a'/>
                                        </div>
                                        <p className='text-level2'>Entrepreneuriat</p>
                                    </div>
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg  '>
                                            <ReactSvg className='w-full h-full  ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Fhigher_education.svg?alt=media&token=83ac7810-c2a4-4d4c-9562-0ae3faa3ef54'/>
                                        </div>
                                        <p className='text-level2'>Enseignement Sup.</p>
                                    </div>
                                
                                    
                                    <div className="flex space-x-3 items-center">
                                        <div className='icon-lg'>
                                        </div>
                                        <p className='text-level2'>...</p>
                                    </div>

                                </div>
                            
                            
                            
                            
                            </div>

                        </div>    */}
                        

                    </div>
                    {/* <div className='w-full flex-1 h-[500px]  flex justify-center items-center'>
                    <CameraIcon className='w-[150px] h-[150px] opacity-10 ' />
                </div> */}
                    {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: ' -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                </div>
            
            </div>
        
        </div>
        {bottomElement}

    </div>
  )
}

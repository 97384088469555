import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = ({menu}: {menu?:boolean}) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
  
    const changeLanguage = (lng:string) => {
      i18n.changeLanguage(lng);
      const currentPath = window.location.pathname.split('/').slice(2).join('/');
      navigate(`/${lng}/${currentPath}`, { replace: true });
    };
  return (
    <div className='flex items-center space-x-4 text-level2'>
      <button onClick={() => changeLanguage('en')} className={i18n.language ==='en' ? `active-lng  ${menu && 'menu'}` : 'disabled:opacity-20 disabled:cursor-not-allowed'} disabled >En</button>
      <button onClick={() => changeLanguage('fr')} className={i18n.language ==='fr' ? `active-lng ${menu && 'menu'}` : ''}>Fr</button>
      <button onClick={() => changeLanguage('es')} disabled className='disabled:opacity-20 disabled:cursor-not-allowed'>Es</button>

    </div>
  );
};

export default LanguageSwitcher;

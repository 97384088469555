import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';
import GlobalLayout from './pages/Layout';
import HomeLayout from './pages/home/HomeLayout';

import { AppContextProvider } from './context/appContext';
import Platform from './pages/home/pages/Platform';
import Solution from './pages/home/pages/Solution';
import Avantages from './pages/home/pages/Avantages';
import HowItWorks from './pages/home/pages/HowItWorks';
import TheyJoined from './pages/home/pages/TheyJoined';
import Demo from './pages/home/pages/Demo';
import About from './pages/home/pages/About';
import Hiring from './pages/home/pages/Hiring';
import Contact from './pages/home/pages/Contact';

import Login from './pages/home/pages/login';
import PrivateRoute from './components/shared/PrivateRoute';
import DeviceLayout from './pages/DeviceLayout';
import './i18n'; 
import Empty from './pages/home/pages/Empty';
import Home from './pages/home/pages/Home';
import QordduFor from './pages/home/pages/QordduFor';
import HomeV2 from './pages/home/pages/HomeV3';
import ProfilesLayout from './pages/profiles/Layout';
import DrawerLayout from './pages/DrawerLayout';
import CommunitiesRoutings from './CommunitiesRoutes';
import { getSubdomain } from './getDomain';
import LoginLayout from './pages/home/pages/login/LoginLayout';


const subdomainRoutes = {
  'communities': <CommunitiesRoutings/>,
}

const App = () => {
  const subdomain = getSubdomain();

  console.log('subdomain', subdomain);
  if (subdomain && (subdomainRoutes as any)[subdomain]) {
    return (subdomainRoutes as any)[subdomain]; // Render the mapped page for the subdomain
  }

  return <RouterProvider router={router} />;
};
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    
    <Route 
      element={<AppContextProvider><Outlet/></AppContextProvider>}
    >
       {/* Redirect from root to default language page */}
       <Route path="/" element={<Navigate to={`/fr`} replace />} />
        
        
        {/* Define routes for pages */}
      <Route element={<DeviceLayout/>}>
          <Route element={<Navigate to={`/fr/login`} replace />} path='/login'/>
          <Route element={<HomeV2/>}>
            <Route element={<></>} path=':lng/'/>
            <Route element={<LoginLayout/>} >
              <Route element={<Login/>} path=':lng/login'/>
            
            </Route>
          </Route>
          </Route>
          <Route element={<ProfilesLayout/>} path=':lng/resources/profiles'/>
   
      

    </Route>
    
    </>

  )
);
root.render(<App />)


import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';

import Login from './';
export default function LoginLayout() {
   const navigate = useNavigate();
   const [open, setopen] = useState(false);
    
   useEffect(() => {
   setopen(true);
   }, []);
    
  return (
   
       <Modal visible={open} onCancel={() => navigate('..')} footer={null} width={400} centered>
        <div className=' h-full flex flex-col justify-center relative overflow-hidden '>
            <div className="h-full  w-full p-[220px] max-[450px]:px-5 relative flex flex-col">
                    {/* <div className="w-full flex  items-center relative mt-[50px] ">
                        <div className="flex space-x-2 items-center justify-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logoq_black.svg?alt=media&token=14900b17-78f4-4977-9105-c8397ab84b02" className='w-auto h-[79px]' alt="" />

                        </div>
                    </div> */}

                <div className="flex  w-full flex-1 h-max">
                          
                    <div className="flex  max-[1050px]:block max-[1050px]:space-y-4 flex-1 max-auto w-max max-w-[1100px]">
                        <div className=' h-max w-[400px]  max-[450px]:w-full space-y-10  '>
                            <div className="space-y-2">
                                <p className="title-level2 ">Connect</p>
                                {/* <p className='text-main'>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima,
                                </p> */}

                            </div>
                            <Login/>

                        </div>
                        <div className='h-[300px] w-[1px] bg-black opacity-20  max-[1050px]:hidden mx-[100px]'>

                        </div>
                        <div className='h-max w-[400px]  max-[450px]:w-full space-y-2'>
                              
                                <p className="title-level2">Request access</p>
                                <div className='space-y-10'>
                                    <p className='text-level2'>
                                        Give us your email to recieve an access
                                    </p>
                                    <div className="space-y-4">
                                        <input
                                        type="email"
                                        placeholder={'Email'}
                                        className={`rounded-lg py-2 px-3 block  w-full h-[50px] !text-[18px] placeholder:text-[18px] `}
                                        // value={email}
                                        // onChange={(e) => {
                                        //     setEmail(e.target.value);
                                        //     setErrors(null);
                                        // }}
                                        // required
                                        />
                                        {/* <Link to="/connect/signup/steps/1" className="text-level2 block w-max bg-gray-300 px-7 py-2.5  rounded-lg " aria-disabled> Signup</Link> */}
                                        <button onClick={() => navigate("/connect/signup/steps/1")} disabled className="disabled:opacity-50 text-level2 block w-max bg-gray-300 px-7 py-2.5  rounded-lg " aria-disabled>
                                        Send
                                        </button>

                                    </div>
                                </div>

                        </div>

                    </div>



                
                </div>
                
            </div>
            {/* <div className='absolute top-[-99px] max-[1600px]:right-[-150px] right-[-214px] h-[calc(100%+140px)] w-[57%]'>
                 <div className="bg-skin-fill-muted h-full w-full -rotate-[8deg] img-login"></div>                   
            </div> */}
        

        </div>

       </Modal>

     
        
  )
}

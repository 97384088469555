export const getSubdomain = () => {
    const host = window.location.hostname; // e.g., "sub.example.com"
    const parts = host.split(".");
    console.log(window.location.hostname)
    if (parts.length > 1) {
      return parts[0]; // Return subdomain (e.g., "sub" from "sub.example.com")
    }
    
    return null; // No subdomain (i.e., main domain)
  };
  
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider, Routes } from "react-router-dom";
import React from 'react';
import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import HomeLayout from './pages/home/HomeLayout';

import Platform from './pages/home/pages/Platform';
import Solution from './pages/home/pages/Solution';
import Avantages from './pages/home/pages/Avantages';
import HowItWorks from './pages/home/pages/HowItWorks';
import TheyJoined from './pages/home/pages/TheyJoined';
import Demo from './pages/home/pages/Demo';
import About from './pages/home/pages/About';
import Hiring from './pages/home/pages/Hiring';
import Contact from './pages/home/pages/Contact';

import Login from './pages/home/pages/login';
import PrivateRoute from './components/shared/PrivateRoute';
import DeviceLayout from './pages/DeviceLayout';
import './i18n'; 
import Empty from './pages/home/pages/Empty';
import Home from './pages/home/pages/Home';
import QordduFor from './pages/home/pages/QordduForD';
import HomeV2 from './pages/home/pages/HomeV3';
import ProfilesLayout from './pages/profiles/Layout';
import DrawerLayout from './pages/DrawerLayout';
import { AppContextProvider } from "./context/appContext";

import ReactDOM from 'react-dom/client';
import GlobalLayout from "./pages/Layout";
import LoginLayout from "./pages/home/pages/login/LoginLayout";
import CommunitiesLayout from "./pages/communities";

export default function CommunitiesRoutings() {
 
  return <RouterProvider router={router} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    
              <Route 
                  element={<AppContextProvider><Outlet/></AppContextProvider>}
                >
                    <Route element={<LoginLayout/>}>
                     <Route element={<Login/>} path=':lng/login'/>
                    </Route>
                     <Route element={<Navigate to={`/fr`} replace />} path='/'/>
                     <Route element={<Navigate to={`/fr/login`} replace />} path='/login'/>

                     <Route element={<PrivateRoute/>}>
                        <Route element={<GlobalLayout/>}>
                            {/* <Route element={<HomeLayout/>}>
                                <Route element={<></>} path=':lng'/>
                    
                                <Route element={<DrawerLayout title='platform'/>}>
                                    <Route element={<Platform/>} path=':lng/platform'/>
                                </Route>
                    
                            
                                <Route element={<DrawerLayout title='solutions'/>}>
                                    <Route element={<Solution/>} path=':lng/solution'/>
                                </Route>
                                <Route element={<DrawerLayout title='qordduFor'/>}>
                                    <Route element={<QordduFor/>} path=':lng/qorddu-for'/>
                                </Route>
                                <Route element={<DrawerLayout title='avantages'/>}>
                                    <Route element={<Avantages/>} path=':lng/avantages'/>
                                </Route>
                                <Route element={<DrawerLayout title='join'/>}>
                                    <Route element={<TheyJoined/>} path=':lng/joined'/>
                                </Route>
                                <Route element={<DrawerLayout title='howItWorks'/>}>
                                    <Route element={<HowItWorks/>} path=':lng/how-it-works'/>
                                </Route>
                                <Route element={<DrawerLayout title='demo'/>}>
                                    <Route element={<Demo/>} path=':lng/demo'/>
                                </Route>
                                <Route element={<DrawerLayout title='about'/>}>
                                    <Route element={<About/>} path=':lng/home/about'/>
                                </Route>
                                <Route element={<DrawerLayout title='jobs'/>}>
                                    <Route element={<Hiring/>} path=':lng/home/hiring'/>
                                </Route>
                                <Route element={<DrawerLayout title=''/>}>
                                    <Route element={<Contact/>} path=':lng/home/contact'/>
                                </Route>
                                
                            
                    
                    
                    
                    
                            
                    
                    
                    
                                <Route element={<></>} path=':lng/*'/>
                                <Route element={<Empty/>} path='*'/>
                    
                    
                    
                    
                    
                    
                    
                    
                                </Route> */}
                            <Route element={<CommunitiesLayout/>}>
                                <Route element={<Platform/>} path=':lng'/>
                                <Route element={<QordduFor/>} path=':lng/qorddu-for'/>
                                <Route element={<HowItWorks/>} path=':lng/comment'/>
                                <Route element={<Avantages/>} path=':lng/solution'/>
                                <Route element={<Avantages/>} path=':lng/avantages'/>
                                <Route element={<Demo/>} path=':lng/demo'/>

                            </Route>
                        </Route>

                    </Route>
        
                     
                     </Route>
    </>

  )
);

import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/appContext';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../utils/firebase';

export default function Login() {
   
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>(null);
 
    const [loading, setloading] = useState(false);
    const logout = async () => {
        await fetch(`${process.env.REACT_APP_API}/auth/logout`, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json", 
                "Access-Control-Allow-Credentials": "true", 
            },
            credentials: "include",
        });
    }
    const submitForm = async (event:any) => {
        event.preventDefault();
         setloading(true)
        setErrors(null)
     

            fetch(`${process.env.REACT_APP_API}/auth/create-session`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json", 
                    "Access-Control-Allow-Credentials": "true", 
                },
                credentials: "include",
                body: JSON.stringify({ email, password }),
            }).then(res => res.json()).then(data => {
                if(data.success){
                    window.open(`${process.env.REACT_APP_ORIGIN_PROTOCOL}://communities.${process.env.REACT_APP_ORIGIN}`);


                }else{
                
                    setErrors('Invalid credentials')
                }
            }).catch(err => setErrors('Something went wrong')).finally(() => setloading(false))
            // window.location.reload();
        
        
      };

  
    
  return (
                      
            <Form className="flex-1 w-full">
                <div className="space-y-3 flex flex-col items-end w-full">
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                        
                    >
                        <input
                        type="email"
                        placeholder={'Email'}
                        className={`rounded-lg py-2 px-3 block  w-full h-[50px] !text-[18px] placeholder:text-[18px] `}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                    >
                        <input
                        type="password"
                        placeholder={'Password'}
                        className="rounded-lg py-2 px-3 block  w-full h-[50px] !text-[18px] placeholder:text-[18px] "
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>

                </div>
                <div className='flex space-x-4 mt-4 items-center'>
                    <Spin spinning={loading}>
                        <div className=''>
                            <button
                            className="text-level2 block  bg-gray-300 px-7 py-2.5  rounded-lg "
                            type="submit"
                            disabled={loading}
                            onClick={submitForm}
                            // style={{background: '#000'}}
                            >
                            {'Login'}
                            </button>

                        </div>
                    </Spin>
                    <div className='w-full flex '>
                        <p className='text-[14px] text-level2'>Forget password?</p>
                    </div>

                </div>
                {/* <button onClick={logout} className='text-level2 block w-full bg-gray-300 px-7 py-2.5  rounded-lg mt-4'>Logout</button> */}
            </Form>
        

                             
               
                
         
     
        
  )
}

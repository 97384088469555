import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { Trans, useTranslation } from 'react-i18next'
import { CameraIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'

import ReactSvg from '../../../components/shared/ReactSvg'
import Phrase from '../components/Phrase'
import { Link } from 'react-router-dom'
import BlockwithImgLayout from '../components/page/block/withImgLayout'
import PhrasesBlock from '../components/page/block/PhrasesBlock'
import CentredBlock from '../components/page/block/CentredBlock'


export default function Avantages() {
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()

  const {t}:any = useTranslation(['common', 'platform'])

 

  useEffect(() => {
    
    document.title = `Qorddu - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full relative mb-[20px]">
       
        <div className="space-between-main-blocks3">
            
            
            
            <div className="mt-[180px] space-y-[220px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto">
                    <div className='flex space-x-[55px] justify-centyer items-center px-page '>
                        <div className=" w-full text-center space-y-10">
                            <div>
                                <p className='title-main w-full'>
                                    Revenez au premier plan 
                                </p>
                                <p className="text-main max-w-[900px] mx-auto">
                                    Offrez une experience web inedite, federez vous en reseaux et ramenez les revenus du web vers vous.

                                </p>

                            </div>
                       
                            

                        </div>
                     

                    </div>
                </div>

                <BlockwithImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        // label={t('platform:p1.label')}
                        label='Rejoindre la grille'
                        // title={t('platform:p1.title')}
                        title="Activez votre serveur Qorddu: c'est gratuit"
                        // text={t('platform:p1.text')}
                        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora quidem voluptatum nihil. Sunt, velit iure corrupti pariatur atque dolorem accusamus, veniam ducimus quia quae voluptas, nostrum porro ex voluptatem reiciendis!"
                    />
                    <PhrasesBlock
                        phrases={[
                            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae',
                            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae',
                            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae'
                        ]}
                    />
                    </>

                </BlockwithImgLayout>
             
                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        // label={t('platform:p1.label')}
                        label='Valorisez votre marque'
                        title="Offrez une experience web inedite et des services Premiums innovants"
                        // text={t('platform:p1.text')}
                        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora quidem voluptatum nihil. Sunt, velit iure corrupti pariatur atque dolorem accusamus, veniam ducimus quia quae voluptas, nostrum porro ex voluptatem reiciendis!"
                    />
                    <div className=" w-full space-y-8">
                                
                        <p className='text-level2 '>Pour en savoir plus sur les services Premiums pour les entreprises et les professionnels, cliquer ci dessus:</p>
                        <Link to={`/`} className='text-level2 mt-0 block w-[200px] !text-white bg-gray-300 px-7 py-2.5 text-center rounded-lg' style={{background: '#606060'}}>En savoir plus</Link>

                    </div>
                    </>

                </BlockwithImgLayout>
                
                <CentredBlock
                    topElement={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--bg-inverted)" fill-opacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
                    }
                    text={
                        <TextBlock2
                        // label={t('platform:p3.label')}
                        label='Augmetez votre visibilité'
                        // title={t('platform:p3.title')}
                        title="L'union fait la force, federez vous en supracommunauté mondiale"
                        // text={t('platform:p3.text')}
                        text="Positionnez votre organisation au coeur d'un secteur d'activités, d'un territoire ou d'une grande communauté en proposant un outil de recherche dédié, une pertinence nouvelle et une richesse fonctionnelle inconnues aujourd'hui."
                    />
                    }
                    blocks={
                        {
                            columns: 3,
                            content:[
                                {
                                    text: 'Votre emplacement Qfs est un véritable moteur de recherche spécialisé.'
                                },
                                {
                                    text: 'Offrez une pertinence sans équivalent avec une centaine de types de publications (entreprises, personnes...).'
                                },
                                {
                                    text: "Votre widget Qfs offre une richesse fonctionnelle inédite dans l'univers du search. "
                                }
                            ]
                        }
                    }
                />

                <BlockwithImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        // label={t('platform:p2.label')}
                        label='Developpez vos revenus'
                        title="Recevez de 25 à 65% des revenus de votre emplacements."
                        // title={t('platform:p2.title')}
                        // text={t('platform:p2.text')}
                        text="Invitez les entreprises et les professionnels de votre secteur, de votre territoire ou de votre communauté à s'abonner à votre emplacement, à y publier et promouvoir leurs messages et offres (produits, services, emplois...). Vous recevrez jusqu'à 60% des revenus générés par votre emplacement."
                    />
                   <PhrasesBlock
                        phrases={['Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ']}
                   />
                    </>

                </BlockwithImgLayout>

                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        // label={t('platform:p2.label')}
                        label='Obtenez des insights'
                        title="Obtenez des stats précieuses sur votre secteur, territoire,...."
                        // title={t('platform:p2.title')}
                        // text={t('platform:p2.text')}
                        text="Votre emplacement Qfs génère du trafic et de l'activité (recherche, ajouts de publications...). Accédez aux rapports statistiques et tirez des enseignements précieux sur le fonctionnement de votre secteur d'activité, de votre territoire...."
                    />
                   <PhrasesBlock
                        phrases={['Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ']}
                   />
                    </>

                </BlockwithImgLayout>

           

              

              

                
                
                
                
            </div>
        </div>
            

             

 

    </div>

             
                      
                    
                    
      
  )
}

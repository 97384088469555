import React from 'react'
import Phrase from '../../Phrase'


type Props = {
    phrases: string[]
}
export default function PhrasesBlock({ phrases }: Props) {
  return (
    <div className=" w-full space-y-8 -ml-[14px]">
        {phrases.map((p,i) => (
            <Phrase key={i} text={p} />

        ))}                       
            
    
        

        
    </div>
  )
}

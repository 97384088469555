import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'


type Props = {
    columns:number,
    content: {
        img?: {type:'icon' | 'image', src:string, dimensions?:string, center?:boolean},
        text:string | JSX.Element, 
        center?:boolean
    }[]
}
export default function Blocks({columns, content}:Props) {

  return (
    <div className={`grid grid-cols-${columns} gap-x-[50px] gap-y-[100px]`}>
    {content.map((block, index)=>(
        <div key={index}>
            <div className="space-y-5">
                <div className={` flex items-center ${block.center ? 'justify-center mx-auto' : ''}`}>
                    <CameraIcon className={` opacity-10  ${block.img?.dimensions ? block.img.dimensions : 'w-[100px] h-[100px] '}`} />
                </div>
                <div>
                    <p className={`text-level2 ${block.center ? 'text-center' : ''}`}>
                       {block.text}

                    </p>

                </div>

            </div>
        
        </div>

    ))}
       

        
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'

import Icon from '../../../components/icons/Icon'
import { useTranslation } from 'react-i18next'
import Resource from '../../../components/resources/Resource'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import BlockwithImgLayout from '../components/page/block/withImgLayout'


export default function QordduFor() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const {t}:any = useTranslation(['common', 'solution'])

  const [scrollTo, setscrollTo] = useState(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop )
  }
  useEffect(() => {
    
    document.title = `Qorddu - ${t('resources.solutions.label')}`
  }, []);
  return (
    <div className="w-full h-full relative">
    
        <div className="">
        
            
            <div className="mt-[180px] space-y-[220px]">
                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        // label={t('solution:p1.label')}
                        label='Presse spécialisée'
                        title={'Proposez un serveur de recherche sectoriel, métier...'}
                        text={'Vous dirigez un groupe de presse spécialisée. Vous couvrez des secteurs tels que les Technologies de l’Information, l’Industrie ou encore l’Agro-alimentaire ? Vous pouvez rejoindre la Grille Qorddu et devenir partenaires d’un des domaines en préparation ou proposer un nouveau domaine. '}
                    />
                     <div className="mt-10 space-y-8">
                        <div className='space-y-3'>
                            <p className='text-level2'>Ils ont réservé leur serveur Qorddu :
                            </p>
                    
                            <div className="flex flex-wrap gap-10">
                                
                                <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                    <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                        <img src="" alt="" />
                                    
                                    </div>
                                    <div>
                                        <p className="font-semibold  text-[16px] ">Silicon.fr</p>
                                        <p className="text-muted ">
                                            Information technologies -France
                                        </p>

                                    </div>
                                
                                </div>
                                <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                    <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                        <img src="" alt="" />
                                    
                                    </div>
                                    <div>
                                        <p className="font-semibold  text-[16px] ">supply-chain.net</p>
                                        <p className="text-muted ">
                                            Logistique -France
                                        </p>

                                    </div>
                                
                                </div>
                                <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                    <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                        <img src="" alt="" />
                                    
                                    </div>
                                    <div>
                                        <p className="font-semibold  text-[16px] ">RIA</p>
                                        <p className="text-muted ">
                                            Agro-alimentaire -France
                                        </p>
                                    

                                    </div>
                                </div>
                            </div>
                        </div>  
                        

                    </div>
                    </>
                </BlockwithImgLayout>
                <BlockwithImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        label={'Presse régionale'}
                        title={'Réservez un serveur de recherche locale'}
                        text={'Vous êtes au cœur de l’information et de l’actualité économique d’une région, d’une province… ? Découvrez les domaines à vocation géographiques que nous préparons pour 2025, et notamment :'}
                    />
                      <div className=" w-full space-y-8 -ml-[14px]">
                            
                            <div className='space-x-7 flex'>
                                
                                <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                <div className='flex-1'>
                                    
                                    <p className="text-level2 !mt-0">
                                    Regional Economy en partenariat avec la presse locale.
                                    </p>
                                </div>
                            </div>
                        
                                <div className='space-x-7 flex'>
                                    <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                    <div className='flex-1'>
                                        
                                        <p className="text-level2 !mt-0">
                                        Innovation Places, domaine dédié aux lieux de l’innovation (technoparcs, incubateurs…).
                                        </p>
                                    </div>
                                </div>
            
                    

                    
                            </div>
                        <div className="mt-10 space-y-8">
                            <div className='space-y-3'>
                                <p className='text-level2'>Ils ont réservé leur serveur Qorddu :
                                </p>
                            
                                    <div className="flex flex-wrap gap-10">
                                
                                    <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                        <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                            <img src="" alt="" />
                                        
                                        </div>
                                        <div>
                                            <p className="font-semibold  text-[16px] ">lamontagne.fr</p>
                                            <p className="text-muted ">
                                                Centre-Val de Loire -France
                                            </p>

                                        </div>
                                    
                                    </div>
                                    <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                        <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                            <img src="" alt="" />
                                        
                                        </div>
                                        <div>
                                            <p className="font-semibold  text-[16px] ">lesaffaires.com</p>
                                            <p className="text-muted ">
                                                Quebec -Canada
                                            </p>

                                        </div>
                                    
                                    </div>
                                    <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                        <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                            <img src="" alt="" />
                                        
                                        </div>
                                        <div>
                                            <p className="font-semibold  text-[16px] ">New York Post</p>
                                            <p className="text-muted ">
                                                New york -Usa
                                            </p>

                                        </div>
                                    
                                    </div>
                                    
                                </div>
                            </div>  
                        </div>
                    </>
                </BlockwithImgLayout>
              
                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        label={'Associations professionnelles'}
                        title={'Offrez un serveur de recherche à votre communauté '}
                        text={'L’édition de journaux ou de revue n’est pas votre cœur de métier mais elle constitue une activité essentielle dans votre mission ? Que vous soyez une chambre de commerce, une collectivités ou une agence de développement économique, vous pouvez rejoindre la Grille Qorddu et proposez un moteur de recherche spécialisés en rapport avec votre territoire.'}
                    />
                     <div className="mt-10 space-y-8">
                        <div className='space-y-3'>
                            <p className='text-level2'>Ils ont réservé leur serveur Qorddu :
                            </p>
                            
                            <div className="flex flex-wrap gap-10">
                            
                                <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                    <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                        <img src="" alt="" />
                                    
                                    </div>
                                    <div>
                                        <p className="font-semibold  text-[16px] ">CCI Aix-Marseille-Provence</p>
                                        <p className="text-muted ">
                                        Marseille -France
                                        </p>

                                    </div>
                                
                                </div>
                                <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                    <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                        <img src="" alt="" />
                                    
                                    </div>
                                    <div>
                                        <p className="font-semibold  text-[16px] ">Chambre de Commerce & d'Industrie d'Alger</p>
                                        <p className="text-muted ">
                                            Alger -Algerie
                                        </p>

                                    </div>
                                
                                </div>
                        
                            
                            </div>
                        </div>  
                    </div>
                    </>
                </BlockwithImgLayout>

                <BlockwithImgLayout orientation='right'>

                    <>
                    <TextBlock2
                            label={'Presse commerciale'}
                            title={'Obtenez des analyses inédites sur votre marque et son écosystème'}
                            text={'Votre marque est au cœur d’un vaste écosystème de professionnels, d’entreprises partenaires, d’organismes de formation… ? Vous publiez une revue ou un journal pour informer votre écosystème ? Vous pouvez avec Qorddu proposer un domaine au nom de votre marque et proposer un réseau de moteur de recherche locaux, chacun couvrant l’écosystème d’un pays spécifique.'}
                        />
                    <div className="mt-10 space-y-8">
                            <div className='space-y-3'>
                                <p className='text-level2'>Ils ont réservé leur serveur Qorddu :
                                </p>
                                
                                <div className="flex flex-wrap gap-10">
                                
                                    <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                        <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                            <img src="" alt="" />
                                        
                                        </div>
                                        <div>
                                            <p className="font-semibold  text-[16px] ">news.microsift.com</p>
                                            <p className="text-muted ">
                                            Microsoft
                                            </p>

                                        </div>
                                    
                                    </div>
                                    <div className={`p-3 pb-[18px] px-5 space-y-2 h-max bg-hover-transparent w-[190px] rounded -ml-5 `} >

                                        <div className={`w-full h-[120px]  relative border border-gray-500 mx-auto`}>
                                            <img src="" alt="" />
                                        
                                        </div>
                                        <div>
                                            <p className="font-semibold  text-[16px] ">se.com</p>
                                            <p className="text-muted ">
                                                Schneider
                                            </p>

                                        </div>
                                    
                                    </div>
                            
                            
                                </div>
                            </div>  
                    </div>
                    </>
                </BlockwithImgLayout>

            


                
                
            </div>
        </div>
            

         

    </div>

             
                      
                    
                    
      
  )
}
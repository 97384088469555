import React from 'react'
import Icon from '../../components/icons/Icon'
import TextBlock2 from '../../components/details/blocks/block2/TextBlock2'
import { Link } from 'react-router-dom'

export default function ProfilesLayout() {
  return (
    <div className='flex justify-center overflow-auto pb-12'>
        <div className='space-between-main-blocks w-full   max-w-[1200px]'>
            <div className='pt-12 pl-page flex justify-between items-center'>
                <img src="https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logoq_black.svg?alt=media&token=14900b17-78f4-4977-9105-c8397ab84b02" className='w-auto h-[79px]' alt="" />
                <button className='btn-gray'>Retour</button>
            </div>
            <div className='space-y-7'>
                <div className="flex space-between-2cols space-x-[30px] pl-page items-center relative">
                    <div>
                        <div className='  space-y-[90px]' >
                            <div className='max-w-[530px]'>
                                <p className="label-level2 ">lorem ispum</p>
                                <p className='title-level1'>Profile</p>
                                <p className="text-level1">
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error aliquid reprehenderit accusantium id unde molestiae dolorum quasi 
                                </p>
                                

                            </div>
                        
                            

                        </div>
                        
                    </div>
                    <div className='flex-1 flex items-center justify-center h-[513px]  w-full bg-skin-fill-muted border'>

                    </div>
                </div>
                

            </div>
            <div className='w-full space-between-blocks'>
                <p className="title-level1 text-center w-full">lorem ipsum dolor</p>
                <div className="w-full flex justify-center">
                    <div className="grid grid-cols-3 gap-5 w-max justify-center">
                        <div className='w-[300px] space-y-2 text-center'>
                            <Icon className='icon-lg mx-auto' name='Document'/>
                            <p className="text-level2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore sit quis nulla consequuntur. 
                            </p>
                        </div>
                        <div className='w-[300px] space-y-2 text-center'>
                            <Icon className='icon-lg mx-auto' name='Document'/>
                            <p className="text-level2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore sit quis nulla consequuntur. 
                            </p>
                        </div>
                        <div className='w-[300px] space-y-2 text-center'>
                            <Icon className='icon-lg mx-auto' name='Document'/>
                            <p className="text-level2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore sit quis nulla consequuntur. 
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className='space-between-2cols w-full'>
                <div className="w-full px-page pb-block">
                    <div className='flex space-x-[90px] justify-between  items-center  ' >
                        <div className=" w-[50%]">
                            <div className=''>
                                <TextBlock2
                                    label={'lorem ipsum'}
                                    title={'Lorem ipsum dolor, sit amet consectetur '}
                                    text={'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit quibusdam corporis doloribus dignissimos, laudantium ab ipsum fuga tempore possimus, itaque natus sed maiores recusandae amet soluta dolorem adipisci, odio illum.'}
                                    
                                />
                                <div className="mt-10 space-y-8">
                                    <div className='space-y-3'>
                                        <div className='space-y-8'>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                        </div>                   
                                            
                                    </div>  
                                

                                </div>

                            </div>
                            

                        </div>
                    
                        <div className=" w-[38%] h-auto">
                            <Icon name='Document' className='w-full h-dull'/>
                        </div>
                    </div>
                    

                </div>
            </div>
            <div className='space-between-2cols w-full'>
                <div className="w-full px-page pb-block">
                    <div className='flex space-x-[90px] justify-between  items-center  ' >
                    <div className=" w-[38%] h-auto">
                            <Icon name='Document' className='w-full h-dull'/>
                        </div>
                        <div className=" w-[50%]">
                            <div className=''>
                                <TextBlock2
                                    label={'lorem ipsum'}
                                    title={'Lorem ipsum dolor, sit amet consectetur '}
                                    text={'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit quibusdam corporis doloribus dignissimos, laudantium ab ipsum fuga tempore possimus, itaque natus sed maiores recusandae amet soluta dolorem adipisci, odio illum.'}
                                    
                                />
                                <div className="mt-10 space-y-8">
                                    <div className='space-y-3'>
                                        <div className='space-y-8'>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                        </div>                   
                                            
                                    </div>  
                                

                                </div>

                            </div>
                            

                        </div>
                    
                    
                    </div>
                    

                </div>
            </div>
            <div className='space-between-2cols w-full'>
                <div className="w-full px-page pb-block">
                    <div className='flex space-x-[90px] justify-between  items-center  ' >
                        <div className=" w-[50%]">
                            <div className=''>
                                <TextBlock2
                                    label={'lorem ipsum'}
                                    title={'Lorem ipsum dolor, sit amet consectetur '}
                                    text={'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sit quibusdam corporis doloribus dignissimos, laudantium ab ipsum fuga tempore possimus, itaque natus sed maiores recusandae amet soluta dolorem adipisci, odio illum.'}
                                    
                                />
                                <div className="mt-10 space-y-8">
                                    <div className='space-y-3'>
                                        <div className='space-y-8'>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                            <div className="flex space-x-3">
                                                <div className='icon-lg'>
                                                    <Icon className='w-full h-full' name='Check'/>
                                                </div>
                                                <p className='text-level2 mt-0 flex-1'>
                                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                                </p>
                                            </div>
                                        </div>                   
                                            
                                    </div>  
                                

                                </div>

                            </div>
                            

                        </div>
                    
                        <div className=" w-[38%] h-auto">
                            <Icon name='Document' className='w-full h-dull'/>
                        </div>
                    </div>
                    

                </div>
            </div>
            <div className="text-center space-y-8 flex flex-col items-center pb-8">
                <p className="title-level1 !text-center w-full">Contact us</p>
                <p className='text-level2 max-w-[700px] text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint qui fugiat est, minus aliquam libero magnam.</p>
                <button className='btn-gray'>Call to action</button>
            </div>
        </div>

    </div>
  )
}

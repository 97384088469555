import React, { useEffect, useRef, useState } from 'react'


import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { useTranslation } from 'react-i18next'
import { CameraIcon } from '@heroicons/react/solid'

import Phrase from '../components/Phrase'
import BlockwithImgLayout from '../components/page/block/withImgLayout'
import PhrasesBlock from '../components/page/block/PhrasesBlock'
import CentredBlock from '../components/page/block/CentredBlock'


export default function HowItWorks() {
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const {t}:any = useTranslation(['common', 'platform'])

 
  useEffect(() => {
    
    document.title = `Qorddu - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full relative mb-[20px]">
       
        <div className="space-between-main-blocks3">
        
            
            
            <div className="mt-[180px] space-y-[220px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto">
                    <div className='flex space-x-[55px] justify-centyer items-center px-page '>
                        <div className=" w-full text-center space-y-10">
                            <div>
                                <p className='title-main w-full'>
                                 Votre serveur 100% geré pour vous
                                </p>
                                <p className="text-main">
                                    Infrastructure, IA, microservices, promotion....Tout est geré en arriere plan

                                </p>

                            </div>
                       
                            

                        </div>
                     

                    </div>
                </div>

                <BlockwithImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        label='Infrastructure'
                        // title={t('platform:p1.title')}
                        title="Qorddu: une plateforme bas sur l'infrastructure mondiale de Microsoft Azure"
                        // text={t('platform:p1.text')}
                        text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. In nihil vel voluptates, laboriosam illo voluptate rem at quo cumque voluptatum quis a atque velit distinctio nisi ullam odio quibusdam culpa."
                    />
                    <PhrasesBlock
                        phrases={['Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ']}
                    />
                    </>

                </BlockwithImgLayout>
              
                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        label='IA'
                        title="Le web de votre secteur ou de votre region indexé et analysé par l'IA"
                        // text={t('platform:p1.text')}
                        text="Lorem ipsum dolor, sit amet consectetur adipisicing elit. In nihil vel voluptates, laboriosam illo voluptate rem at quo cumque voluptatum quis a atque velit distinctio nisi ullam odio quibusdam culpa."
                    />
                    <PhrasesBlock
                        phrases={['Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ']}
                    />
                    </>

                </BlockwithImgLayout>
                

                <CentredBlock
                    topElement={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--bg-inverted)" fill-opacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
                    }
                    text={
                        <TextBlock2
                        // label={t('platform:p3.label')}
                        label='Microservices'
                        // title={t('platform:p3.title')}
                        title="Proposez une suite de microservices"
                        // text={t('platform:p3.text')}
                        text=''
                    />
                    }
                    blocks={
                        {
                            columns:4,
                            content: [
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                },
                                {
                                    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus obcaeca '
                                }
                            ]
                        }
                    }
                    bottomElement={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--bg-inverted)" fill-opacity="1" d="M0,64L1440,256L1440,0L0,0Z"></path></svg>
                    }
                
                />

               

                <BlockwithImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        // label={t('platform:p2.label')}
                        label='La proposition Qorddu'
                        title="Une expérience web inédite pour les utilisateurs et les entreprises"
                        // title={t('platform:p2.title')}
                        // text={t('platform:p2.text')}
                        text=''
                    />
                    <PhrasesBlock
                        phrases={['Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ', 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut commodi molestiae ']}
                    />
                    </>

                </BlockwithImgLayout>

              

                
             

                

                
              


                
                
                
                
            </div>
        </div>
            

             

 

    </div>

             
                      
                    
                    
      
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { useAppContext } from '../../../context/appContext'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import { useTranslation } from 'react-i18next'
import { CameraIcon } from '@heroicons/react/solid'


import BlockwithImgLayout from '../components/page/block/withImgLayout'
import PhrasesBlock from '../components/page/block/PhrasesBlock'
import CentredBlock from '../components/page/block/CentredBlock'
import Blocks from '../components/page/block/Blocks'
import HorizontalBlock from '../components/page/block/HorizontalBlock'


export default function Platform() {

  const {t}:any = useTranslation(['common', 'platform'])



  useEffect(() => {
    
    document.title = `Qorddu - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full relative mb-[20px]">
       
        <div className="space-between-main-blocks3">
      
            
            
            <div className="mt-[180px] space-y-[220px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto">
                    <div className='flex space-x-[55px] justify-between items-center px-page '>
                        <div className=" w-[50%] space-y-10">
                            <div>
                                <p className='title-main'>
                                 Une grille de <br/> 100.000 serveurs 
                                </p>
                                <p className="text-main">
                                pour organiser l'information sur le web

                                </p>

                            </div>
                       
                            

                        </div>
                        <div className='w-[500px] h-[500px]  flex justify-center items-center'>
                            <CameraIcon className='w-[150px] h-[150px] opacity-10 ' />
                        </div>

                    </div>
                </div>
                <BlockwithImgLayout orientation='right'>
                    <>
                        <TextBlock2
                            // label={t('platform:p1.label')}
                            label=''
                            // title={t('platform:p1.title')}
                            title="Le web : des millions de sites d’informations... et des millions de plateformes et de contenus dispersés "
                            // text={t('platform:p1.text')}
                            text="Annuaires, journaux, sites d’annonces (emplois, immobiliers…), places de marchés, bases de connaissances… Le web fourmille de millions de sites d’informations spécialisés offrant des contenus utiles aux utilisateurs et des audiences recherchées par les entreprises. Le problème ? Ces sites relèvent d’une conception artisanale : chaque site repose sur une plateforme « maison » conçue « dans un coin ».  Résultats : "
                        />
                    


              
                        <Blocks
                            columns={2}
                            content={[
                                { 
                                    text: <>
                                            Pour <span className='font-bold text-level2'>les utilisateurs</span> : un accès compliqué et fastidieux : Urls et interfaces différentes, mises en page hétérogènes, pauvreté des services proposés... 
                                    
                                    </> 
                                },
                                { 
                                    text:  <>
                                    Pour <span className='font-bold text-level2'>les entreprises</span>: un usage chronophage et couteux : audiences fragmentées, outils d’édition propriétaires, abonnements et facturation multiples… 
                            
                            </>
                                },
                            ]}
                        />
                    </>

                </BlockwithImgLayout>
              
          
                

           

                <div>
                    
                    <CentredBlock
                        topElement={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--bg-inverted)" fill-opacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
                        }
                        text={
                            <TextBlock2
                                // label={t('platform:p3.label')}
                                label=''
                                // title={t('platform:p3.title')}
                                title="Qorddu : en trois points "
                                // text={t('platform:p3.text')}
                                text=''
                            />
                        }
                        blocks={{
                            columns: 3,
                            content:[
                                {
                                    text: <>
                                     <span className='font-bold text-level2'>Une grille de 100.000 serveurs spécialisés</span> déployés industriellement, verticale par verticale, (entrepreneuriat, biotechs, IA, Enseignement supérieur, Industrie…) et pays par pays (USA, Espagne…). 
                                        
                                    </> 
                                },
                                {
                                    text: <>
                                    A chaque serveur, <span className='font-bold text-level2'>une suite de microservices</span> et de contenus ultra pertinents : un moteur de recherche, un système d’annuaire, un journal, un agenda collectif (salon…), etc. 
                                       
                                   </>  
                                },
                                {
                                    text:
                                    <>
                                    <span className='font-bold text-level2'>Un accès intuitif et unifié</span> pour explorer la grille, consulter les serveurs, exploiter les microservices, publier des données (produits, emplois…), analyser une audience ou un trafic…  
                                       
                                   </>   
                                }
                            ]
                        }}
                    />
                 

                </div>

                <BlockwithImgLayout orientation='left'>
                    <>
                    <div>
                        <TextBlock2
                            // label={t('platform:p2.label')}
                            label=''
                            title="Une grille de 100.000 serveurs spécialisés "
                            // title={t('platform:p2.title')}
                            // text={t('platform:p2.text')}
                            text='Qorddu est une plateforme nativement conçue pour déployer plus de 100.000 « serveurs spécialisés » en une vaste grille organisée : '
                        />
                    

                    </div>
                   <Blocks
                    columns={2}
                    content={[
                        { 
                            text: <>
                                <span className='font-bold text-level2'>En plusieurs centaines de verticales </span>: les biotechs, l’industrie, l’IA, le marketing digital, l’agro-industrie, l’Enseignement Supérieur, les énergies renouvelables, etc. 
                            </>
                        },
                        { 
                            text: <>
                                <span className='font-bold text-level2'>En plus de 10.000 territoires </span>: les pays mais aussi les états, régions et provinces (Québec, Paca…), les comtés et département (Alpes-Maritimes, Comté de San Diego…). 
                            </> 
                        },
                    ]}
                   />
                    </>
                </BlockwithImgLayout>
      

                    <div className='max-w-page mx-auto space-y-16'>
                        <div className='w-[50%]'>
                        <TextBlock2
                                // label={t('platform:p2.label')}
                                label=''
                                title="Un serveur Qorddu : un marché et une suite de microservices et de contenus ultra pertinents   "
                                // title={t('platform:p2.title')}
                                // text={t('platform:p2.text')}
                                text="Un serveur Qorddu est un « site spécialisé » dont les services et contenus sont axés sur un couple Thématique / Territoire défini : les biotechs au Canada, l’IA en Californie, etc. Chaque serveur Qorddu offre une suite de « microservices » ultra pertinents  "
                            />
                

                        </div>
                        <div className="grid grid-cols-4 gap-[50px]">
                            <HorizontalBlock 
                                title='Websearch '
                                text='Un accès aux ressources web (pages, images…) d’un site , d’un marché, d’une communauté….'
                            />
                            <HorizontalBlock 
                                title='Jobs  '
                                text='Un système d’annonces emplois/CV d’un marché donné. '
                            />
                            <HorizontalBlock 
                                title='Directory   '
                                text='Un système d’annuaire pour identifier les personnes et les entreprises  '
                            />
                            <HorizontalBlock 
                                title='Forum  '
                                text='Un outil de discussion et de partages d’idées, de best practices…  '
                            />
                            <HorizontalBlock 
                                title='Journal  '
                                text='Un système de journal ouvert aux personnes et aux entreprises d’un marché. .'
                            />
                            <HorizontalBlock 
                                title='Knowledge  '
                                text='Gestion de connaissances sur un marché donné (formation, cours, tutos…). '
                            />
                            <HorizontalBlock 
                                title='Events  '
                                text='Un agenda collectif (salons, conférences, rencontres…) d’un marché. '
                            />
                            <HorizontalBlock 
                                title='Et d’autres microservices à venir  '
                                text='Une roadmap à trois ans accessible sous condition. '
                            />
                        </div>
                

                    </div>


                <BlockwithImgLayout orientation='left'>
                    <>
                    <div>
                        <TextBlock2
                            // label={t('platform:p2.label')}
                            label=''
                            title="Un accès intuitif et unifié "
                            // title={t('platform:p2.title')}
                            // text={t('platform:p2.text')}
                            text='La grille de Serveur Qorddu est accessible depuis une interface web et, bientôt, une application, unifiées et intuitives. Résultat : un saut de productivité pour : '
                        />
                    

                    </div>
                   <Blocks
                    columns={2}
                    content={[
                        { 
                            text: <>
                                <span className='font-bold text-level2'>Les utilisateurs </span>: une seule interface pour naviguer à travers la grille, consulter les serveurs, effectuer des recherches dans les moteurs, les annuaires, les journaux… Epingler et partager les données, communiquer et réseauter avec d’autres utilisateurs…
                            </> 
                        },
                        { 
                            text: <>
                                <span className='font-bold text-level2'>Les entreprises </span>: une seule interface, un seul système d’abonnement/facturation pour publier (fiches produits, offres d’emplois, actualité…), analyser les audiences, le trafic reçu…
                            </> 
                        },
                    ]}
                   />
                    </>
                </BlockwithImgLayout>




         

              

                
                
                
                
            </div>
        </div>
            

             

 

    </div>

             
                      
                    
                    
      
  )
}

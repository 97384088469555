import React from 'react'
import Icon from '../../../../../components/icons/Icon'
import { CameraIcon } from '@heroicons/react/solid'


type Props = {
    title: string,
    text: string,
    icon?:string
}
export default function HorizontalBlock({title, text, icon}:Props) {
  return (
    <div className='flex space-x-3'>
        {icon ? (
            <Icon className='w-[50px] h-[50px]' name={icon} />
        ) : (
            <CameraIcon className='w-[50px] h-[50px] opacity-10' />
        )}
        <div className="flex-1">
            <p className="text-level2 font-semibold">{title}</p>
            <p className="text-level2">
                {text}
            </p>
        </div>
    </div>
  )
}

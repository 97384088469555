import React from 'react'

type Props = {
    label:string,
    title:string,
    text:string
}
export default function TextBlock2({ label, title, text } : Props) {
  return (
    <div>
        <h2 className='label-level2'>{label}</h2>
        <h3 className="title-level2 text-inherit">{title}</h3>
        <p className='text-level2'>{text}</p>
    </div>
  )
}

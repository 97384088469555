import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, OutletProps, useLocation } from 'react-router-dom'
import Loading from './Loading'
import { useAppContext } from '../../context/appContext'

export default function PrivateRoute(props:OutletProps) {
  const [isLoaded, setisLoaded] = useState(false)
  const [user, setuser] = useState<null | {role?:string, id:string}>(null);
  const { setUser } = useAppContext()
  useEffect(() => {
    (async () => {
      try{
        const response = await fetch(`${process.env.REACT_APP_API}/auth/check-session`, {
          method: "GET",
          credentials: "include",
          headers:{
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Origin": "http://localhost:3001",
          }
          
      })
  
      if (response.ok) {
          const data = await response.json();
          if(data.role === 'super_admin' || data.role === 'admin' || data.role === 'guest'){
            setuser({id:data.uid,role:data.role})
            setUser({name: data.name!, id: data.uid, photo: data.picture ? data.picture : undefined, email: data.email!})
          }else{
            setuser({id:data.uid})
                  setUser(null)
          }
        
          console.log("User logged in on Main Domain & Subdomain1");
        }
        setisLoaded(true)
        
      }catch(e){
        console.log(e)
        setisLoaded(true)
      }
    })()
  }, [])
 
  if(!isLoaded){
    return <Loading/>
  }
  if(!user){
      return <Navigate to='/login' replace  /> 
  }
  if(user && !user.role){
      return <Navigate to='/unauthorized' replace  /> 
  }
  if(user?.role){
    return <Outlet {...props}/>
  }
  return <></>

}

// export default function PrivateRoute(props:OutletProps) {
//     const [isLoaded, setisLoaded] = useState(false)
//     const [user, setuser] = useState<null | {role?:string, id:string}>(null);
//     const { setUser } = useAppContext()
//     useEffect(() => {
//       console.log('hi')
//       const listen = onAuthStateChanged(auth, async user => {
//         const claims = await user?.getIdTokenResult()
//         console.log(user,'user')
//         if(user){
//             if((claims?.claims.role === 'super_admin' || claims?.claims.role === 'admin' || claims?.claims.role === 'guest')){
//               setuser({id:user.uid,role:claims?.claims.role})
//               setUser({name: user.displayName!, id: user.uid, photo: user.photoURL ? user.photoURL : undefined, email: user.email!})
//             }else{
//                 setuser({id:user.uid})
//                 setUser(null)
//             }
//             console.log(user)
//         }else{
//             setuser(null)
//             setUser(null)
//         }
//         setisLoaded(true)
//       })
//       return listen
//     }, [])

   
//     if(!isLoaded){
//       return <Loading/>
//     }
//     if(!user){
//         return <Navigate to='/login' replace  /> 
//     }
//     if(user && !user.role){
//         return <Navigate to='/unauthorized' replace  /> 
//     }
//     if(user?.role){
//       return <Outlet {...props}/>
//     }
//     return <></>
 
// }

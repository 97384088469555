import React from 'react'
import Icon from '../../../components/icons/Icon'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'antd'

export default function HomeV3() {
    const navigate = useNavigate()
    const { i18n} = useTranslation()
  return (
    
     <div className={`space-between-main-blocks3 overflow-auto`} >
        <div className='space-y-7 h-full'>
            <div className="flex space-between-2cols items-center h-full justify-center pl-[220px] ">
                <div className='flex-1'>
                    <div className=' ' >
                        <div className=' '>
                            <div className='flex space-x-3 items-center absolute top-[20px]'>
                                <img className='w-auto h-[79px]' src='https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logoq_black.svg?alt=media&token=14900b17-78f4-4977-9105-c8397ab84b02'/>
                            </div>
                            <div>
                                <p className="label-level2 text-[30px] leading-[37px]">Acteurs de la presse</p>
                                <p className='title-main'>
                                  Revenez au premier plan 
                                </p>
                                <p className="text-main">
                                Devenez partenaire d'un serveur Qorddu.

                                </p>

                            </div>
                                <div className="space-y-[100px] mt-16">
                                    {/* <div className="grid grid-cols-3 gap-x-[50px] ">
                                        <div>
                                            <div className="space-y-5">
                                                <Icon name='Naviguer' className='w-[60px] h-[60px]'/>
                                                <div>
                                                    <p className="text-level2">
                                                    Offrez une <span className='font-bold text-level2'>expérience Search inédite</span> dans votre secteur, votre région...
        
                                                    </p>
        
                                                </div>
        
                                            </div>
                                        
                                        </div>
                                        <div>
                                            <div className="space-y-5">
                                                <Icon name='Search' className='w-[60px] h-[60px]'/>
                                                <div>
                                                    <p className="text-level2">
                                                    L'union fait la force : 
                                                    <span className='font-bold text-level2'> fédérez-vous en réseau </span>verticaux.
                                                    </p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <div>
                                            <div className='space-y-5'>
                                                <Icon name='Exploitez' className='w-[60px] h-[60px]'/>
                                                <div>
                                                    <p className="text-level2">
                                                        
        
                                                        <span className="font-bold text-level2">Proposez des services innovants </span>et ramenez les revenus du Search vers vous.
                                                    </p>
                                                </div>
                                            </div>
                                        
                                        </div>
        
                                        
                                    </div> */}
        
                                    <div className='flex space-x-3'>
                                        <Link to={`/${i18n.language}/login`} className='text-level2 block w-[200px] !text-white bg-gray-300 px-7 py-2.5  rounded-lg' style={{background: '#606060'}}>Connectez vous</Link>
                                        
                                        {/* <button className='btn-gray'>En savoir plus</button> */}
        
                                    </div>
                                </div>
                            {/* <div className='flex justify-end mt-[90px]'>
                                <div className=' flex justify-end pr-8'>
                                    <p className='max-w-[250px]  text-level2 text-end'>
                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel illo eni
                                    </p>
                                </div>
                                <div className="max-w-[350px]  text-level2">
                                    <form className=" space-y-3">
                                        <input type="text" className='border rounded w-full mb-2 !text-[19px]  placeholder:text-[19px] h-[59px]' placeholder='your email' />
                                        <input type="password" className='border rounded w-full !text-[19px] h-[59px] placeholder:text-[19px]' placeholder='your password' />
                                    </form>
                                        <button className='ml-auto  text-level2 block w-[200px] bg-gray-300 text-white px-7 py-2.5  rounded-lg mt-4' style={{background: '#606060'}}>Connectez vous</button>
                                </div>
                            </div> */}
                            {/* <button className='px-4 py-2 rounded-lg border border-main text-level2' onClick={() => navigate(`/${i18n.language}/resources`)}>En savoir plus</button> */}


                        </div>
                        {/* <div className="space-y-[100px]">
                            <div className="grid grid-cols-3 gap-x-[50px] ">
                                <div>
                                    <div className="space-y-5">
                                        <Icon name='Naviguer' className='w-[60px] h-[60px]'/>
                                        <div>
                                            <p className="text-level2">
                                            Offrez une <span className='font-bold text-level2'>expérience Search inédite</span> dans votre secteur, votre région...

                                            </p>

                                        </div>

                                    </div>
                                
                                </div>
                                <div>
                                    <div className="space-y-5">
                                        <Icon name='Search' className='w-[60px] h-[60px]'/>
                                        <div>
                                            <p className="text-level2">
                                            L'union fait la force : 
                                            <span className='font-bold text-level2'> fédérez-vous en réseau </span>verticaux.
                                            </p>
                                        </div>
                                    </div>
                                
                                </div>
                                <div>
                                    <div className='space-y-5'>
                                        <Icon name='Exploitez' className='w-[60px] h-[60px]'/>
                                        <div>
                                            <p className="text-level2">
                                                

                                                <span className="font-bold text-level2">Proposez des services innovants </span>et ramenez les revenus du Search vers vous.
                                            </p>
                                        </div>
                                    </div>
                                
                                </div>

                                
                            </div>

                            <div className='flex space-x-3'>
                                <button className='text-level2 block w-[200px] bg-gray-300 px-7 py-2.5  rounded-lg' style={{background: '#606060'}}>Connectez vous</button>
                                <button className='btn-gray'>En savoir plus</button>

                            </div>
                        </div> */}

                    </div>
                    
                </div>
                  {/* <div className='w-[900px] flex items-center justify-center relative ' style={{perspective: '1000px'}}>
                                <img src='/images/img.svg' className='w-full h-auto' style={{transform: 'rotateY(-32deg)' }}/>

                            </div> */}
                <div className="w-[900px] h-screen">
                    {/* <Carousel className='h-screen'  autoplay={true} autoplaySpeed={2000} effect='fade'>
                        <div className='w-full overflow-hidden h-screen'>
                            <div className='w-full flex items-center justify-center relative h-screen' style={{perspective: '1000px'}}>
                                <img src='/images/img.svg' className='w-full h-auto' style={{transform: 'rotateY(-32deg)' }}/>

                            </div>


                        </div>
                     
                        <div className='w-full overflow-hidden h-screen'>
                            <div className='w-full flex items-center justify-center relative h-screen' style={{perspective: '1000px'}}>
                                <img src='/images/img2.svg' className='w-full h-auto' style={{transform: 'rotateY(-32deg)' }}/>

                            </div>


                        </div>
                        <div className='w-full overflow-hidden h-screen'>
                            <div className='w-full flex items-center justify-center relative h-screen' style={{perspective: '1000px'}}>
                                <img src='/images/img3.svg' className='w-full h-auto' style={{transform: 'rotateY(-32deg)' }}/>

                            </div>


                        </div>
                        <div className='w-full overflow-hidden h-screen'>
                            <div className='w-full flex items-center justify-center relative h-screen' style={{perspective: '1000px'}}>
                                <img src='/images/img4.svg' className='w-full h-auto' style={{transform: 'rotateY(-32deg)' }}/>

                            </div>


                        </div>
                    </Carousel> */}
                    <div className='absolute top-[-99px] max-[1600px]:right-[-150px] right-[-214px] h-[calc(100%+140px)] w-[50%]'>
                            <div className="bg-skin-fill-muted h-full w-full -rotate-[8deg]" style={{background: 'url(../images/cyber-security.png)', backgroundRepeat: 'no-repeat',backgroundPosition: '-3px -133px' }}></div>                   
                        </div>
                </div>
            </div>
            {/* <div className='absolute bottom-3 right-3'>
                <div className="bg-white teddra-rounded teddra-shadow p-4 w-[450px] flex space-x-5">
                    <div className='w-[170px] h-[170px] rounded bg-skin-fill-muted'></div>
                    <div className="flex-1">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores eius, quo reprehenderit illo magnam quia tenetur delectus ea? Adipisci dolorum porro iusto nam deserunt ad odit, sapiente </p>
                        <button className='bg-gray-300 px-7 py-2.5 mt-3 rounded-lg' onClick={() => window.open(`http://communities.localhost:3000/${i18n.language}/resources`)}>En savoir plus</button>


                    </div>
                </div>
            </div> */}
            

        </div>
            <Outlet/>            
                          
    </div>
  )
}
